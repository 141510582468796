import * as $ from 'jquery';

var header;
var scene0;
var scene1;
var scene2;
var scene3;
var scene4;
var scene5;
var scene6;
var scene7;
var scene8;
var scene9;
var innerH;

$(document).ready(function () {
    header = $('header');

    scene0 = $('.s0');
    scene1 = $('.s1');
    scene2 = $('.s2');
    scene3 = $('.s3');
    scene4 = $('.s4');
    scene5 = $('.s5');
    scene6 = $('.s6');
    scene7 = $('.s7');
    scene8 = $('.s8');
    scene9 = $('.s9');


    innerH = (window.innerHeight * 0.4);

    init();
    if ($('.s1').length) {
        changeActiveScenes();
    }
});


/**
 * Initialization
 */
function init() {
    if ($('.s1').length) {
        bindEvents();
    } else {
        return
    }
}

/**
 * Bind events
 */
function bindEvents() {
    $(window).scroll(changeActiveScenes);
}

/**
 * removeHeaderClassess
 */
function removeHeaderClassess() {
    header.removeClass('a1 a2 a3 a4 a5 a6 a7 a8 a9');
}

/**
 * removeSceneClassess
 */
function cleanScene(sceneNumber) {
    switch (sceneNumber) {
        case 0:
            scene0.removeClass('a1 a2 a3 a4 a5 a6 a7 a8 a9');
            break;
        default:
            break;
    }
}

function changeActiveScenes() {
    if ((header.offset().top + innerH) > scene1.offset().top && (header.offset().top + innerH) < scene2.offset().top) {
        $('.js-main-menu a').removeClass('active');
        if (!scene0.hasClass('a1')) {
            cleanScene(0);
            scene0.addClass('a1');
        }
        if (!scene1.hasClass('shown')) {
            scene1.addClass('shown');
            scene2.removeClass('shown');
        }
        if (!header.hasClass('a1')) {
            removeHeaderClassess();
            header.addClass('a1');
        }
    }
    if ((header.offset().top + innerH) > scene2.offset().top && (header.offset().top + innerH) < scene3.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(1)').addClass('active');
        if (!scene0.hasClass('a2')) {
            cleanScene(0);
            scene0.addClass('a2');
        }
        if (!scene2.hasClass('shown')) {
            scene1.removeClass('shown');
            scene2.addClass('shown');
            scene3.removeClass('shown');
        }
        if (!header.hasClass('a2')) {
            removeHeaderClassess();
            header.addClass('a2');
        }
    }

    if ((header.offset().top + innerH) > scene3.offset().top && (header.offset().top + innerH) < scene4.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(2)').addClass('active');
        if (!scene0.hasClass('a3')) {
            cleanScene(0);
            scene0.addClass('a3');
        }
        if (!scene3.hasClass('shown')) {
            scene2.removeClass('shown');
            scene3.addClass('shown');
            scene4.removeClass('shown');
        }
        if (!header.hasClass('a3')) {
            removeHeaderClassess();
            header.addClass('a3');
        }
    }
    if ((header.offset().top + innerH) > scene4.offset().top && (header.offset().top + innerH) < scene5.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(3)').addClass('active');
        if (!scene0.hasClass('a4')) {
            cleanScene(0);
            scene0.addClass('a4');
        }
        if (!scene4.hasClass('shown')) {
            scene3.removeClass('shown');
            scene4.addClass('shown');
            scene5.removeClass('shown');
        }
        if (!header.hasClass('a4')) {
            removeHeaderClassess();
            header.addClass('a4');
        }
    }
    if ((header.offset().top + innerH) > scene5.offset().top && (header.offset().top + innerH) < scene6.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(4)').addClass('active');
        if (!scene0.hasClass('a5')) {

            cleanScene(0);
            scene0.addClass('a5');
        }
        if (!scene5.hasClass('shown')) {
            scene4.removeClass('shown');
            scene5.addClass('shown');
            scene6.removeClass('shown');
        }
        if (!header.hasClass('a5')) {
            removeHeaderClassess();
            header.addClass('a5');
        }
    }
    if ((header.offset().top + innerH) > scene6.offset().top && (header.offset().top + innerH) < scene7.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(5)').addClass('active');
        if (!scene0.hasClass('a6')) {
            cleanScene(0);
            scene0.addClass('a6');
        }
        if (!scene6.hasClass('shown')) {
            scene5.removeClass('shown');
            scene6.addClass('shown');
            scene7.removeClass('shown');
        }
        if (!header.hasClass('a6')) {
            removeHeaderClassess();
            header.addClass('a6');
        }
    }
    if ((header.offset().top + innerH) > scene7.offset().top && (header.offset().top + innerH) < scene8.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(6)').addClass('active');
        if (!scene0.hasClass('a7')) {
            cleanScene(0);
            scene0.addClass('a7');
        }
        if (!scene7.hasClass('shown')) {
            scene6.removeClass('shown');
            scene7.addClass('shown');
            scene8.removeClass('shown');
        }
        if (!header.hasClass('a7')) {
            removeHeaderClassess();
            header.addClass('a7');
        }
    }
    if ((header.offset().top + innerH) > scene8.offset().top) {
        $('.js-main-menu a').removeClass('active');
        $('.js-main-menu a:nth-child(7)').addClass('active');
        if (!scene0.hasClass('a8')) {
            cleanScene(0);
            scene0.addClass('a8');
        }
        if (!scene8.hasClass('shown')) {
            scene8.addClass('shown');
        }
        if (!header.hasClass('a8')) {
            removeHeaderClassess();
            header.addClass('a8');
        }
    }
}


