import * as $ from 'jquery';
import 'jquery-contextmenu';
import {SwiperGreeting} from './slider-greeting';

export {changeText, changeSvg}

var textBox;
var svgArrow;

$(document).ready(function () {
    textBox = $(".context-menu-one");
    svgArrow = $("#arrowSvg");
    init();
});

/**
 * Initialization
 */
function init() {
    $.contextMenu({
        trigger: 'left',
        selector: '.context-menu-one',
        callback: function (key, options) {
            switch (key) {
                case 'slide1':
                    SwiperGreeting.slideTo(1);
                    changeText('e-commerce');
                    changeSvg();
                    $('.context-menu-item').removeClass('active');
                    $('.context-menu-item:nth-child(1)').addClass('active');
                    break;
                case 'slide2':
                    SwiperGreeting.slideTo(2);
                    changeText('international trade');
                    changeSvg();
                    $('.context-menu-item').removeClass('active');
                    $('.context-menu-item:nth-child(2)').addClass('active');
                    break;
                case 'slide3':
                    SwiperGreeting.slideTo(3);
                    changeText('startups');
                    changeSvg();
                    $('.context-menu-item').removeClass('active');
                    $('.context-menu-item:nth-child(3)').addClass('active');
                    break;
                case 'slide4':
                    SwiperGreeting.slideTo(4);
                    changeText('freelancers');
                    changeSvg();
                    $('.context-menu-item').removeClass('active');
                    $('.context-menu-item:nth-child(4)').addClass('active');
                    break;
                default:
                    break;
            }
        },
        items: {
            "slide1": {name: "e-commerce"},
            "slide2": {name: "international trade"},
            "slide3": {name: "startups"},
            "slide4": {name: "freelancers"},
        }
    });

    $('.context-menu-item:nth-child(1)').addClass('active');
}

/**
 * Change text
 */
function changeText(t) {
    textBox.fadeOut(function () {
        textBox.html(t);
        textBox.fadeIn();
    });
}

/**
 * Change svg
 */
function changeSvg() {
    svgArrow.fadeOut(function () {
        svgArrow.html();
        svgArrow.fadeIn();
    });
}

