import * as $ from 'jquery';
import Chart from 'chart.js';
import select2 from 'select2';

var commissions;


/**
 * Initialization
 */
function init() {
    if (!$('#tarifs-section').length) {
        return;
    }
    getCommissions();
    initSelect2();
    bindEvents();
}

/**
 * Bind events
 */
function bindEvents() {
    $("#bank-country, #transfer-currency").on("change", defineCommission);
    $('.commisions__toggle__btn').on('click', function () {
        $(this).parent().toggleClass('open');
        if ($(this).parent().hasClass('open')) {
            $('.commisions__title').html('Download <br>pricelists');
        } else {
            $('.commisions__title').html('Sending <br>transfers');

        }
    });
    $('[data-domestic="false"]').on('click', function () {
        event.preventDefault();
        event.stopPropagation();
        $('[data-domestic="false"]').removeClass('active');
        $(this).addClass('active');

        if ($(this).attr('data-sha') === 'true') {
            $('#sending-transfer-commission').html(20 + ' <span>' + 'EUR' + '</span>');
        } else {
            defineCommission();
        }
    });
}


/**
 * Get countries and append to selects
 */
function getCountryList(countries, currencies) {
    var countriesList = [];
    var currencesList = [];

    for (var key in countries) {
        countriesList.push('<option value="' + countries[key].code.toLowerCase() + '">' + countries[key].name + '</option>');
    }

    for (var key in currencies) {
        currencesList.push('<option value="' + currencies[key].toLowerCase().slice(0, 2) + '">' + currencies[key] + '</option>');
    }

    $('select#bank-country').append(countriesList);
    $('select#transfer-currency').append(currencesList);

    $('select#bank-country').val('cz');
    $('select#transfer-currency').val('eu');
}

/**
 * Slect Init
 */
function initSelect2() {
    $("#bank-country").select2({
        theme: "acct_light",
        width: '100%'
    });

    $("#transfer-currency").select2({
        templateResult: function (state) {
            return $('<span class="flag-icon flag-icon-' + state.id + '"></span><span>' + state.text + '</span>');
        },
        templateSelection: function (state) {
            return $('<span class="flag-icon flag-icon-' + state.id + '"></span><span>' + state.text + '</span>');
        },
        theme: "acct_light",
        width: '100%'
    });

    var arrowSvg = '<svg width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg"><path id ="svg-arrow" d="M5.29289 0.707032L6 -7.50422e-05L6.70711 0.707032L12 5.99992L11.2929 6.70703L6 1.41414L0.707107 6.70703L0 5.99992L5.29289 0.707032Z"/></svg>';
    $('.select2-has-arrow .select2-selection__arrow ').html(arrowSvg).trigger('change');
}

/**
 * Define commissions
 */
function defineCommission() {
    if (!commissions) {
        return;
    }

    var countryCode = $('select#bank-country :selected').val();
    var currency = $('select#transfer-currency :selected').text();
    var isDomestic = false;
    var price = 0;
    var data = commissions;

    for (var keyCurr in data.transfers.domestic) {
        if (currency === data.transfers.domestic[keyCurr].currency) {
            for (var keyCounrty in data.transfers.domestic[keyCurr].countries) {
                if (countryCode === data.transfers.domestic[keyCurr].countries[keyCounrty].toLowerCase()) {
                    isDomestic = true;
                    price = data.transfers.domestic[keyCurr].price;
                    $('#sending-transfer-commission').html(price + ' <span>' + currency + '</span>');
                }
            }
        } else {
            $('#sending-transfer-commission').html(data.transfers.swift.price + ' <span>' + data.transfers.swift.currency + '</span>');
        }
    }
    if (!isDomestic) {
        $('.commisions-widget__transfer-type [data-domestic="false"]').addClass('shown')
        $('.commisions-widget__transfer-type [data-domestic="true"]').removeClass('shown');
    } else {
        $('.commisions-widget__transfer-type [data-domestic="true"]').addClass('shown')
        $('.commisions-widget__transfer-type [data-domestic="false"]').removeClass('shown')
    }

}

/**
 * Get commissions json
 */
function getCommissions() {
    $.getJSON('home/transfer-commissions', function (data) {
        commissions = data;
        getCountryList(data.countries, data.currencies);
        defineCommission();
    });
}

$(document).ready(function () {
    init();
});
