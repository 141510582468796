import * as $ from 'jquery';
import Swiper from 'swiper';
import {changeText, changeSvg} from './context-menu';

var SwiperGreeting;

export {SwiperGreeting};

$(document).ready(function () {
    SwiperGreeting = new Swiper('.swiper-greeting .swiper-container', {
        loop: true,
        effect: 'fade',
        speed: 800,
        preloadImages: true,
        autoplay: {
            delay: 8000,
        }
    }).on('slideChange', function () {
        switch (SwiperGreeting.realIndex) {
            case 0:
                changeText('e-commerce');
                changeSvg();
                $('.context-menu-item').removeClass('active');
                $('.context-menu-item:nth-child(1)').addClass('active');
                break;
            case 1:
                changeText('international trade');
                changeSvg();
                $('.context-menu-item').removeClass('active');
                $('.context-menu-item:nth-child(2)').addClass('active');
                break;
            case 2:
                changeText('startups');
                changeSvg();
                $('.context-menu-item').removeClass('active');
                $('.context-menu-item:nth-child(3)').addClass('active');
                break;
            case 3:
                changeText('freelancers');
                changeSvg();
                $('.context-menu-item').removeClass('active');
                $('.context-menu-item:nth-child(4)').addClass('active');
                break;
            default:
                break;
        }
    });
});