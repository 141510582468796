import * as $ from 'jquery';

var slideList;

$(document).ready(function () {
    slideList = $('.swiper-greeting .swiper-slide > span');
    init();
});

/**
 * Initialization
 */
function init() {
    setSlideHeight();
    bindEvents();
}

/**
 * Bind events
 */
function bindEvents() {
    $(window).resize(function () {
        setSlideHeight();
    });
}

/**
 * Find and set slide height
 */

function setSlideHeight() {
    var greetingWrapperSM = $('.greeting__wrapper:not(.greeting__wrapper_additional)');
    var slideHeightSM = greetingWrapperSM.outerHeight();

    slideList.each(function (index, element) {
        $(this).attr('style', 'height: ' + slideHeightSM + 'px;');
    });
}