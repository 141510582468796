import * as $ from 'jquery';
import Chart from 'chart.js';
import select2 from 'select2';

var primaryCurrencyInput;

var commissionInfo;

var exchangeResult;

var currenciesInfo;

var exchangeChart;

var ctx;

var gradientStroke;

var gradientFill;

var chartData;

var chartOptions;

/**
 * Initialization
 */
function init() {
    if (!$('#tarifs-section').length) {
        return;
    }

    primaryCurrencyInput = $('input#primary-currency-field');

    commissionInfo = $('#total-commission-info');

    exchangeResult = $('#exchange-result');

    ctx = document.getElementById('myChart').getContext("2d");

    gradientStroke = ctx.createLinearGradient(15, 133, 149, 0.1);
    gradientStroke.addColorStop(0, 'rgba(15, 133, 149, 0.1)');
    gradientStroke.addColorStop(1, 'rgba(15, 133, 149, 0.1)');

    gradientFill = ctx.createLinearGradient(15, 133, 149, 0.1);
    gradientFill.addColorStop(0, "rgba(15, 133, 149, 0.1)");
    gradientFill.addColorStop(1, "rgba(15, 133, 149, 0.1)");

    chartData = {
        labels: [],
        datasets: [{
            data: [],
            borderColor: '#0F8595',
            cubicInterpolationMode: 'default',
            borderWidth: 1,
            lineTension: 0.4,
            pointRadius: [],
            pointStyle: 'rect',
            hoverRadius: [],
            pointBorderColor: '#0F8595',
            pointBackgroundColor: '#0F8595',
            pointHoverBackgroundColor: '#0F8595',
            pointBorderWidth: 3,
            pointHoverBorderWidth: 3,
            fill: true,
            backgroundColor: gradientFill
        }]
    };

    chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        titleMarginBottom: 0,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0
            }
        },
        title: {
            display: true,
            text: '',
            fontSize: 0,
            padding: 0,
            lineHeight: 0
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                display: false,
            }],
            yAxes: [{
                display: false,
            }]
        },
        tooltips: {
            enabled: false
        }
    };

    exchangeChart = createGraph();
    prepareCurencyList();
    initSelect2();
    bindEvents();
}

/**
 * Bind events
 */
function bindEvents() {
    $("#primary-currency-field").on("input", recalculateCurrencyExchange);
    $('select#primary-currency-select').on("select2:select", recalculateWithRequest);
    $('select#secondary-currency-select').on("select2:select", recalculateWithRequest);
    $("input[name='exchange-type']").on("change", recalculateWithRequest);
}

/**
 *
 */
function recalculateWithRequest() {
    var form = getCalculationData();
    prepareCurrenciesInfo(form.currencyPrimary, form.currencySecondary);

    var url = 'home/currencies/';
    if (form.isDirect) {
        url += form.currencyPrimary + '/' + form.currencySecondary;
    } else {
        url += form.currencySecondary + '/' + form.currencyPrimary;
    }

    $.ajax({
        dataType: "json",
        url: url,
        success: function (data) {
            var archiveRates = [];
            var archiveDates = [];
            var pointRadiuses = [];
            var hoverRadiuses = [];
            var archive = data.archive;
            var today = (new Date()).toISOString().substring(0, 10);

            // Add labels
            var directPrice = form.isDirect ? data.price : Number((1 / data.price).toFixed(5));
            currenciesInfo = data;
            $('#rate-info').html('1 ' + form.currencyPrimary.toUpperCase() + ' = ' + directPrice + ' ' + form.currencySecondary.toUpperCase());


            // Work with graph
            if (!archive.length || archive[archive.length - 1].tick_date !== today) {
                archive.push({
                    price: data.price,
                    tick_date: today
                });
            }

            for (let key in archive) {
                archiveRates.push(+archive[key].price);
                archiveDates.push(archive[key].tick_date);
                if (archive[key].tick_date === today) {
                    pointRadiuses.push(2);
                    hoverRadiuses.push(3);
                } else {
                    pointRadiuses.push(0);
                    hoverRadiuses.push(0);
                }
            }

            updateGraph(archiveRates, archiveDates, pointRadiuses, hoverRadiuses);

            // Recalculate
            recalculateCurrencyExchange();
        },
        error: getErrorInfo
    });
}

/**
 * Recalculate Currency Exchange
 */
function recalculateCurrencyExchange() {
    var totalBase;
    var total;
    var form = getCalculationData();
    var quantity = +primaryCurrencyInput.val();
    var commissionPercent = getCurrencyExchangeCommission(quantity);
    var labelExchangeRequest = $('#exchange-request-title');
    var labelExchangeCurrency = $('#exchange-currency-title');
    var labelResultRequest = $('#exchange-result-title');

    if (form.isDirect) {
        totalBase = currenciesInfo.price * quantity;
    } else {
        totalBase = 1 / currenciesInfo.price * quantity;
    }

    var totalCommission = totalBase * commissionPercent;

    if (form.isDirect) {
        total = totalBase + totalCommission;
    } else {
        total = totalBase - totalCommission;
    }

    // Labels
    if (form.isDirect) {
        labelExchangeRequest.html('I want to receive');
        labelExchangeCurrency.html('I want to change');
        labelResultRequest.html('I Have to Change');

    } else {
        labelExchangeRequest.html('I want to change');
        labelExchangeCurrency.html('I want to receive');
        labelResultRequest.html('Total to be received');
    }

    exchangeResult.html(total.toFixed(2) + '<span>' + form.currencySecondary + '</span>');
    commissionInfo.html('Including commission of ' + totalCommission.toFixed(2) + ' ' + form.currencySecondary + ' / ' + commissionPercent * 100 + '%');
}

/**
 * Find appropriate commission percent
 * @param quantity
 * @return {number}
 */
function getCurrencyExchangeCommission(quantity) {
    var percent;

    for (var key in currenciesInfo.commissions) {
        percent = +currenciesInfo.commissions[key].percent / 100;
        if (quantity > +currenciesInfo.commissions[key].before) {
            continue;
        }

        break;
    }

    return percent;
}

function getCalculationData() {
    return {
        currencyPrimary: $('select#primary-currency-select').find(':selected').val(),
        currencySecondary: $('select#secondary-currency-select').find(':selected').val(),
        isDirect: ($("input[name='exchange-type']:checked").val() === "want-get")
    }
}

/**
 * Grab currencies info from JSON
 */
function prepareCurrenciesInfo(primaryCurrency, secondaryCurrency) {
    /**
     * @todo set not active option on select2 (does not work now!!!)
     */

    $('select#primary-currency-select option').removeAttr('disabled');
    $('select#primary-currency-select option[value=' + secondaryCurrency + ']').attr('disabled', 'disabled');

    $('select#secondary-currency-select option').removeAttr('disabled');
    $('select#secondary-currency-select option[value=' + primaryCurrency + ']').attr('disabled', 'disabled');
}

/**
 * Get currencies and append to selects
 */
function prepareCurencyList() {
    $.getJSON('home/exchangeable-currencies', function (data) {
        var items = [];

        $.each(data, function (key, val) {
            items.push('<option value="' + val + '">' + val + '</option>');
        });

        $('select#primary-currency-select, select#secondary-currency-select').append(items);

        $('select#primary-currency-select').val('EUR');
        $('select#secondary-currency-select').val('USD');
        recalculateWithRequest();
    });
}

/**
 * Find min/max currency price and upload date
 */
function uploadMinMaxPrice(data) {
    var minPrice = 1000000000;
    var maxPrice = 0;

    if (data.archive && data.archive.length) {
        for (key in data.archive) {
            if (+data.archive[key].price > maxPrice) maxPrice = +data.archive[key].price;
            if (+data.archive[key].price < minPrice) minPrice = +data.archive[key].price;
        }

        $('#price-max').html(maxPrice);
        $('#price-min').html(minPrice);
    } else {
        $('#price-max').html(0);
        $('#price-min').html(0);
    }
}

/**
 * Get ajax error info
 */
function getErrorInfo(jqXHR) {
    $('.exchange-footer-text').addClass('hidden');
    $('.exchange-footer-error').html(jqXHR.responseJSON.message).removeClass('hidden');
    $('#tarifs-section').addClass('error');
    $('#price-max, #price-min').html('');
    $('#rate-info').html(jqXHR.responseJSON.message);
}

/**
 * Slect Init
 */
function initSelect2() {
    $("#primary-currency-select").select2({
        theme: "acct_light",
        width: 90
    });
    $("#secondary-currency-select").select2({
        theme: "acct_light",
        width: '100%'
    });

    var arrowSvg =
        '<svg width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">' +
        '<path id ="svg-arrow" d="M5.29289 0.707032L6 -7.50422e-05L6.70711 0.707032L12 5.99992L11.2929 6.70703L6 1.41414L0.707107 6.70703L0 5.99992L5.29289 0.707032Z"/>' +
        '</svg>';
    $('.select2-has-arrow .select2-selection__arrow ').html(arrowSvg).trigger('change');
}

/**
 * Graph init
 */
function createGraph() {
    exchangeChart = new Chart(document.getElementById('myChart').getContext('2d'), {
        type: 'line',
        data: chartData,
        options: chartOptions
    });

    return exchangeChart;
}

/**
 * Graph update
 */
function updateGraph(data, labels, pointRadiuses, hoverRadiuses) {
    exchangeChart.data.datasets[0].data = data;
    exchangeChart.data.labels = labels;
    exchangeChart.data.datasets[0].pointRadius = pointRadiuses;
    exchangeChart.data.datasets[0].hoverRadius = hoverRadiuses;
    exchangeChart.update();
}

$(document).ready(function () {
    init();
});

